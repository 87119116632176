import PlaceIcon from '@mui/icons-material/Place'
import {
	Container,
	Grid,
	Typography,
	Box,
	Button,
	Card,
	CardActionArea,
	CardMedia,
	CardContent
} from '@mui/material'
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'
import waves from 'src/assets/images/waves.gif'
import { Pages } from 'src/config/routers/Router'
import { scrollAnimationProps } from 'src/shared/utils/utils'

import styles from './Home.module.css'
export const Home = () => {
	return (
		<>
			<div className={styles.twPortada}>
				<Container className={styles.twBloque}>
					<Typography className={styles.title} component="h1" variant="h3" color="primary.contrastText">
						Observatorio Territorial para la Acción <br />
						Hidrosocial CAUCE <br />
					</Typography>
					<img className={styles.twWaves} src={waves} />
				</Container>
			</div>

			<Box flex={1} bgcolor="#fff" color="#000">
				<Container>
					<Grid container bgcolor="primary" justifyContent="center" spacing={2} mt={-20}>
						{Object.values(Pages)
							.slice(2, 5)
							.map(({ name, path, background }) => (
								<Grid
									className={styles.twGridCard}
									item
									bgcolor="primary"
									component="div"
									xs={12}
									md={3}
									key={name}
								>
									<motion.div {...scrollAnimationProps('top')}>
										<Card className={styles.twTarjeta}>
											<CardActionArea>
												<CardMedia
													className={styles.twTarjetaImage}
													image={background}
													title="Contemplative Reptile"
												/>
											</CardActionArea>
											<CardContent className={styles.twButtonContainer}>
												<Button
													size="small"
													color="primary"
													variant="contained"
													component={NavLink}
													to={path}
													disableElevation
												>
													{name}
												</Button>
											</CardContent>
										</Card>
									</motion.div>
								</Grid>
							))}
					</Grid>
				</Container>
				<Container>
					<Grid></Grid>
					<Grid container>
						<Grid item alignItems="center" py={10} xs={12}>
							<Grid container px={5} alignItems="center" justifyContent="space-around" py={2}>
								<Grid item>
									<PlaceIcon fontSize="large" color="secondary" />
								</Grid>
							</Grid>
							<Grid px={5}>
								<Typography
									variant="h5"
									align="justify"
									textAlign="center"
									pb={5}
									textTransform="uppercase"
								>
									Las herramientas que promovemos son: dialogo de Saberes,
									<br />
									coproducción de conocimiento y <br />
									gobernanza comunitaria.
								</Typography>
								<img className={styles.twWaves} src={waves} />
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</>
	)
}
