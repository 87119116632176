import { Routes, Route } from 'react-router-dom'
import { Page } from 'src/shared/types/router'

import { AgapeHidricoContainer } from '../agapeHidrico/AgapeHidrico.container'
import { ObservatorioCauceContainer } from '../observatorioCauce/ObservatorioCauce.container'
import { TeamContainer } from '../team/Team.container'
import { AboutPath } from './routes'

export const AboutPages: Record<keyof typeof AboutPath, Page> = {
	Team: {
		name: 'EQUIPO',
		path: AboutPath.Team,
		Component: TeamContainer
	},
	AgapeHidrico: {
		name: 'PROYECTO AGAPE HÍDRICO',
		path: AboutPath.AgapeHidrico,
		Component: AgapeHidricoContainer
	},
	ObservatorioCauce: {
		name: 'OBSERVATORIO CAUCE',
		path: AboutPath.ObservatorioCauce,
		Component: ObservatorioCauceContainer
	}
}

export const Router = () => {
	return (
		<Routes>
			{Object.values(AboutPages).map(({ path, Component }, i) => (
				<Route key={i + path} path={path} element={<Component />} />
			))}
		</Routes>
	)
}
