import { FC, createRef } from 'react'

import { Box, Typography } from '@mui/material'
import classNames from 'classnames/bind'
import alberto from 'src/assets/images/people/alberto.jpg'
import alexander from 'src/assets/images/people/alexander.jpg'
import carlos from 'src/assets/images/people/carlos.jpg'
import lorena from 'src/assets/images/people/lorena.jpeg'
import pablo from 'src/assets/images/people/pablo.jpg'
import rocio from 'src/assets/images/people/rocio.jpg'
import { TeamDataFragment } from 'src/graphql/autogenerate/generated'

import styles from './CardPerson.module.css'

const cx = classNames.bind(styles)

const people = {
	alberto,
	alexander,
	carlos,
	lorena,
	pablo,
	rocio
}

type CardPersonProps = {
	person: TeamDataFragment
	setSelectedPerson: (person: TeamDataFragment) => void
	isSelected: boolean
}

export const CardPerson: FC<CardPersonProps> = ({ person, setSelectedPerson, isSelected }) => {
	const ref = createRef<HTMLDivElement>()
	// const isHovering = useHoverDirty(ref)

	const handleOnClick = () => setSelectedPerson(person)

	return (
		<div
			ref={ref}
			className={cx(styles.twPerson, {
				twPersonSelected: isSelected
			})}
			onClick={handleOnClick}
		>
			<div className={styles.twBoxAvatar}>
				<Box
					className={cx(styles.twAvatar, {
						twAvatarInitial: !isSelected
					})}
					sx={{
						backgroundImage: `url(${people[person.image_path as keyof typeof people]})`
					}}
				/>
			</div>
			<Typography component="p" align="center" fontWeight={isSelected ? 'bold' : ''}>
				{person.name}
			</Typography>
		</div>
	)
}
