import { useEffect, useState } from 'react'

import EmailIcon from '@mui/icons-material/Email'
import TwitterIcon from '@mui/icons-material/Twitter'
import { Box, CircularProgress, Container, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { TeamDataFragment, useGetTeamQuery } from 'src/graphql/autogenerate/generated'

import { CardPerson } from './components'
import styles from './Team.module.css'

export const Team = () => {
	const { data, loading } = useGetTeamQuery()
	const [team, setTeam] = useState<Array<TeamDataFragment>>([])
	const [selectedPerson, setSelectedPerson] = useState<TeamDataFragment>()

	useEffect(() => {
		if (!loading) {
			setTeam(data?.team || [])
			setSelectedPerson((data?.team[0] as TeamDataFragment) || {})
		}
	}, [loading])

	return (
		<>
			<div className={styles.twPortada}>
				<div className={styles.twBloque}></div>
			</div>
			<Box flex={1} py={10} bgcolor="#fff">
				<Container>
					<Typography variant="h5" align="justify" fontWeight="semi-bold">
						La experiencia se desarrolló el primer semestre del año 2022, el objetivo principal es
						“Visibilizar la desigualdad estructural y multidimensional de la crisis hídrica en Chile”.
						Para ello, sumamos las experiencias de personas ligadas a procesos comunitarios, analisis
						tecnicos y activistas, de esta forma vamos configurando un proceso co-construido en dialogo
						con los contextos de quienes participan activamente en la defensa y construcción de
						propuestas para los desarrollos de los territorios.
					</Typography>

					{!loading ? (
						<Box pt={10}>
							<Typography component="h3" variant="h3" align="center" fontWeight="bold" pb={2}>
								CONOCE AL EQUIPO
							</Typography>

							<Typography component="h1" variant="h5" align="center">
								Nuestro equipo tiene experiencia en proyectos interdisciplinario, trabajo
								comunitario y producción de conocimiento colaborativo.
							</Typography>

							<Box mx="auto" width={200} pt={5} />

							<Grid container spacing={4}>
								<Grid item xs={12} md={6}>
									<Grid container spacing={10} justifyContent="center" pt={5}>
										{team &&
											team.map((person) => (
												<Grid item xs={6} sm={4} key={person.name}>
													<CardPerson
														isSelected={person.id === selectedPerson?.id}
														setSelectedPerson={setSelectedPerson}
														person={person}
													/>
												</Grid>
											))}
									</Grid>
								</Grid>
								<Grid item xs={12} md={6} px={4}>
									<Box
										boxShadow={2}
										py={5}
										px={5}
										borderRadius={1}
										textAlign="justify"
										minHeight={'100%'}
										display="flex"
										flexDirection="column"
										justifyContent="center"
										alignItems="center"
										gap={2}
									>
										<Box className="flex gap-2 items-center">
											<Typography variant="h5" align="center" fontWeight="bold">
												{selectedPerson?.name}{' '}
											</Typography>
											<div className="animate-[wave_2s_linear_infinite]">👋🏻</div>
										</Box>
										<Typography align="justify" pt={2}>
											{selectedPerson?.description}
										</Typography>
										<Box className="flex gap-2">
											{selectedPerson && (
												<Tooltip title="LinkedIn">
													<IconButton
														color="info"
														href={selectedPerson?.twitter_url}
														target="_blank"
													>
														<TwitterIcon className="hover:animate-wave" />
													</IconButton>
												</Tooltip>
											)}
											<Tooltip title="Email">
												<IconButton
													color="info"
													href={`mailto:${selectedPerson?.email}?subject=Contacto Observatorio`}
												>
													<EmailIcon className="hover:animate-wave" />
												</IconButton>
											</Tooltip>
										</Box>
									</Box>
								</Grid>
							</Grid>
						</Box>
					) : (
						<Stack direction="row" justifyContent="center" p={10}>
							<CircularProgress />
						</Stack>
					)}
				</Container>
			</Box>
		</>
	)
}
