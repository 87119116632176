import Carousel, { autoplayPlugin } from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import { Box, Container, Typography } from '@mui/material'
import {
	LIGUA1,
	LIGUA2,
	LIGUA3,
	RANQUIL1,
	RANQUIL2,
	RANQUIL3,
	RANQUIL4,
	RANQUIL5,
	RANQUIL6,
	VALPO1,
	VALPO2,
	VALPO3,
	VALPO4
} from 'src/assets/images'
import waves from 'src/assets/images/waves.gif'

import styles from './AgapeHidrico.module.css'

const images = [
	VALPO1,
	VALPO2,
	VALPO3,
	VALPO4,
	LIGUA1,
	LIGUA2,
	LIGUA3,
	RANQUIL1,
	RANQUIL2,
	RANQUIL3,
	RANQUIL4,
	RANQUIL5,
	RANQUIL6
]
export const AgapeHidrico = () => {
	return (
		<>
			<div className={styles.twPortada}>
				<div className={styles.twBloque}></div>
			</div>
			<Box flex={1} py={10} bgcolor="#fff">
				<Container>
					<Box>
						<Typography variant="h2" align="center" fontWeight="bold" pb={5}>
							PROYECTO - AGAPE HIDRÍCO
						</Typography>

						<Typography variant="h5" align="justify" mb={5}>
							El proyecto Ágapes Hídricos tuvo lugar en Petorca de la Región de Valparaíso, Ránquil
							de la Región de Ñuble y Curacautín de la Región de la Araucanía, territorios de Chile.
							<br />
							Las organizaciones que participan en la experiencia son Fundación Enlace Cultural,
							Unión comunal de Comités de Agua Potable Rural de Ránquil, Mujeres MODATIMA, mujeres
							lideresas del agua y defensoras territoriales.
							<br />
							La experiencia se desarrolló el primer semestre del 2022, cuyo objetivo principal es
							“Visibilizar la desigualdad estructural y multidimensional de la crisis hídrica en
							Chile”. Para ello, desarrollamos una serie de entrevistas a mujeres dirigentes de
							organizaciones comunitarias ligadas a temáticas hídricas en 3 territorios del país,
							Petorca, Ránquil y Curacautín. También tuvimos la posibilidad de entrevistar a mujeres
							del Biobío defensoras de la naturaleza de Humedal de Hualpen y Estero de Penco para el
							buen uso comunitario y la educación ambiental.
							<br />
							En estas entrevistas se aborda la dimensión social de la crisis hídrica y las
							interseccionalidades en temas diversos (alimentación, salud, derechos sexuales
							reproductivos, economías de subsistencia, escasez hídrica, educación, etc.). Este
							material único luego se dispone como micro videos en la plataforma web.
							<br />
							En la búsqueda de reciprocidad con las organizaciones, luego de una conversación sobre
							requerimientos, se apoya con equipo pozometro y herramientas a la Unión Comunal de Agua
							Potable Rural de Ránquil para su uso comunitario.
							<br />
							En paralelo se realiza levantamiento de información cartográfica de los territorios que
							sirven para que las organizaciones analicen y generen acciones a posterior. Los
							desafíos que, como equipo ampliado, dirigentas y técnicos-políticos fueron parte de un
							proceso de diálogo de saberes, colaborativo y horizontal, que fue adecuándose con
							diferentes matices en los diversos territorios. El producto final del proyecto es una
							plataforma web “Observatorio Cauce” que es un espacio de visibilización en la propia
							voz de quienes día a día cuidan, defienden y abordan las necesidades de los habitantes
							y sus territorios.
							<br />
							La sostenibilidad y desarrollo de esta plataforma se hace mediante acuerdos para
							proyectos de continuidad en las provincias de Petorca e Itata. Algunas entrevistas las
							cerramos con un Ágape, un espacio para compartir sabores y saberes, de experiencias del
							cuidado, relacionadas a la reproducción ampliada de la vida y a las esperanzas de
							mujeres con diversos trayectos de vida y de acción política por la dignidad de sus
							territorios.
						</Typography>

						<img className={styles.twWaves} src={waves} />
					</Box>

					<Box py={10}>
						<Typography variant="h3" align="center" pb={5}>
							FINANCIADORES
						</Typography>

						<Typography variant="h5" align="justify" mb={5}>
							Este proyecto fue producido y financiado en marco del proyecto GRRIPP: Gender
							Responsive Resilience and Intersectionality in Policy and Practice (
							<a
								className={styles.twLink}
								href="http://www.grripp.net"
								target="_blank"
								rel="noreferrer"
							>
								www.grripp.net
							</a>
							) liderado por University College London y, en la región de América Latina y el Caribe,
							gestionado por la Pontificia Universidad Católica del Perú. Este proyecto fue
							financiado a través de la iniciativa Global Challenges Research Fund de UKRI.
						</Typography>

						<img className={styles.twWaves} src={waves} />
					</Box>

					<Box>
						<Typography variant="h3" align="center" pb={5}>
							MATERIAL AUDIOVISUAL
						</Typography>

						<Carousel
							plugins={[
								'arrows',
								'infinite',
								{
									resolve: autoplayPlugin,
									options: {
										interval: 2000
									}
								}
							]}
							className={styles.twCarousel}
							animationSpeed={1000}
						>
							{images.map((image, index) => (
								<div
									key={index}
									className={styles.twImage}
									style={{ backgroundImage: `url(${image})` }}
								/>
							))}
						</Carousel>
					</Box>
				</Container>
			</Box>
		</>
	)
}
