import { useEffect, useState } from 'react'

import PlaceIcon from '@mui/icons-material/Place'
import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material'
import { GoogleMap, InfoWindowF, Marker, useJsApiLoader } from '@react-google-maps/api'
import ReactPlayer from 'react-player'
import { useGetMarkersQuery, GetMarkersQuery } from 'src/graphql/autogenerate/generated'
import { GOOGLE_API_KEY } from 'src/shared/utils/constants'

import styles from './Communities.module.css'

const center = { lat: -34.47751052707529, lng: -71.30058467893288 }

export const Communities = () => {
	const { data, loading } = useGetMarkersQuery()

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: GOOGLE_API_KEY
	})
	const [markers, setMarkers] = useState<GetMarkersQuery['marker']>([])
	const [selectedIndex, setSelectedIndex] = useState<number>(-1)

	useEffect(() => {
		if (isLoaded && !loading) {
			setMarkers(data?.marker || [])
			setSelectedIndex(9)
		}
	}, [isLoaded, loading])

	const handleOnClickMarker = (index: number) => {
		setSelectedIndex(index)
	}

	const handleContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.preventDefault()

	return (
		<Box flex={1} py={5} color="#fff" mb={8}>
			<Container className={styles.twMap1Container}>
				<Box flex={1} mb={6}>
					<Typography variant="h2" align="center" fontWeight="bold" textAlign="center">
						VOCES POR EL AGUA
					</Typography>
					<Typography variant="h4" align="center" mt={2}>
						¡ Selecciona una ubicación
						<PlaceIcon className={styles.twPlace} fontSize="large" /> y descubre la comunidad !
					</Typography>
				</Box>
			</Container>
			<Container className={styles.twMap1Container}>
				{isLoaded && !loading ? (
					<>
						<div className={styles.twMap}>
							<GoogleMap mapContainerClassName={styles.twMap1} zoom={6} center={center}>
								{markers.map(({ entrevistados, ...position }, index) => {
									return (
										<Marker
											key={index}
											position={position}
											onClick={() => handleOnClickMarker(index)}
											animation={
												selectedIndex === index ? google.maps.Animation.BOUNCE : undefined
											}
										>
											{selectedIndex === index && (
												<InfoWindowF position={position}>
													<div className={styles.twInfoWindow}>
														<p>
															<b>Entrevistado:</b>
														</p>
														<p>{entrevistados}</p>
													</div>
												</InfoWindowF>
											)}
										</Marker>
									)
								})}
							</GoogleMap>
						</div>

						{selectedIndex >= 0 && (
							<div className={styles.twVideo}>
								<ReactPlayer
									className={styles.twPlayer}
									controls
									playing
									onContextMenu={handleContextMenu}
									config={{
										file: {
											attributes: {
												controlsList: 'nodownload'
											}
										}
									}}
									height="100%"
									width="100%"
									preload={1}
									url={markers[selectedIndex].videos || ''}
									onError={() => {}}
								/>
							</div>
						)}
					</>
				) : (
					<Grid container justifyContent="center">
						<Grid item>
							<CircularProgress color="inherit" />
						</Grid>
					</Grid>
				)}
			</Container>
		</Box>
	)
}
