import { Variants } from 'framer-motion'

export const scrollAnimationProps = (entry: 'top' | 'bottom' | 'left' | 'right', duration = 1.2) => {
	const offset = 100
	const entries = {
		top: { y: -offset },
		bottom: { y: offset },
		left: { x: -offset },
		right: { x: offset }
	}

	const variants: Variants = {
		offscreen: {
			opacity: 0,
			...entries[entry]
		},
		onscreen: {
			opacity: 1,
			x: 0,
			y: 0,
			transition: {
				duration
			}
		}
	}

	return {
		initial: 'offscreen',
		whileInView: 'onscreen',
		variants
	}
}
