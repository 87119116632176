import { useEffect, useState } from 'react'

import PlaceIcon from '@mui/icons-material/Place'
import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material'
import { GoogleMap, InfoWindowF, Marker, useJsApiLoader } from '@react-google-maps/api'
import {
	useGetMarkersIdiosincraciasHidricasQuery,
	GetMarkersIdiosincraciasHidricasQuery
} from 'src/graphql/autogenerate/generated'
import { GOOGLE_API_KEY } from 'src/shared/utils/constants'

import styles from './IdiosincrasiasHidricas.module.css'

const center = { lat: -36.69356644873099, lng: -72.78845236475286 }

export const IdiosincrasiasHidricas = () => {
	const { data, loading } = useGetMarkersIdiosincraciasHidricasQuery()

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: GOOGLE_API_KEY
	})
	const [markers, setMarkers] = useState<GetMarkersIdiosincraciasHidricasQuery['marker']>([])
	const [selectedIndex, setSelectedIndex] = useState<number>(-1)

	// const src = 'https://developers.google.com/maps/documentation/javascript/examples/kml/westcampus.kml'

	const handleOnClickMarker = (index: number) => {
		setSelectedIndex(index)
	}

	useEffect(() => {
		if (isLoaded && !loading) {
			setMarkers(data?.marker || [])
			setSelectedIndex(30)
		}
	}, [isLoaded, loading])

	return (
		<Box flex={1} py={5} color="#fff" mb={8}>
			<Container>
				<Box flex={1} mb={4}>
					<Typography variant="h2" align="center" fontWeight="bold" textAlign="center">
						IDIOSINCRASIAS HÍDRICAS
					</Typography>
					<Typography variant="h4" align="center" mt={2}>
						¡ Selecciona una ubicación
						<PlaceIcon className={styles.twPlace} fontSize="large" /> y descubre el sector !
					</Typography>
				</Box>
			</Container>
			<Container>
				{isLoaded && !loading ? (
					<GoogleMap
						id="kml-layer-example"
						mapContainerClassName={styles.twMap2}
						zoom={11}
						mapTypeId="terrain"
						center={center}
					>
						{/* <KmlLayer url={src} options={{ suppressInfoWindows: true, preserveViewport: false }} /> */}
						{markers.map(({ title, ...position }, index) => {
							return (
								<Marker
									key={index}
									position={position}
									onClick={() => handleOnClickMarker(index)}
									animation={selectedIndex === index ? google.maps.Animation.BOUNCE : undefined}
								>
									{selectedIndex === index && (
										<InfoWindowF position={position}>
											<div className={styles.twInfoWindow}>
												<p>
													<b>Sector:</b>
												</p>
												<p>{title}</p>
											</div>
										</InfoWindowF>
									)}
								</Marker>
							)
						})}
					</GoogleMap>
				) : (
					<Grid container justifyContent="center">
						<Grid item>
							<CircularProgress color="inherit" />
						</Grid>
					</Grid>
				)}
			</Container>
		</Box>
	)
}
