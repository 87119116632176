import Carousel, { autoplayPlugin } from '@brainhubeu/react-carousel'
import { Box, Container, Typography } from '@mui/material'
import {
	ARAUCANIA1,
	ARAUCANIA2,
	ARAUCANIA3,
	ARAUCANIA4,
	ARAUCANIA5,
	ARAUCANIA6,
	ARAUCANIA7,
	ARAUCANIA8,
	ARAUCANIA9
} from 'src/assets/images'
import waves from 'src/assets/images/waves.gif'

import styles from './ObservatorioCauce.module.css'

export const ObservatorioCauce = () => {
	const images = [
		ARAUCANIA1,
		ARAUCANIA2,
		ARAUCANIA3,
		ARAUCANIA4,
		ARAUCANIA5,
		ARAUCANIA6,
		ARAUCANIA7,
		ARAUCANIA8,
		ARAUCANIA9
	]

	return (
		<>
			<div className={styles.twPortada}>
				<div className={styles.twBloque}></div>
			</div>
			<Box flex={1} py={10} bgcolor="#fff">
				<Container>
					<Box>
						<Typography variant="h2" align="center" fontWeight="bold" pb={5}>
							OBSERVATORIO - CAUCE
						</Typography>

						<Typography variant="h5" align="justify" mb={5}>
							La escasez hídrica en Chile es consecuencia del modelo de desarrollo económico
							extractivista y la crisis climática. Se vincula con las diferentes formas en como la
							sociedad se relaciona con la naturaleza, que en menor o mayor medida degradan los
							bienes comunes naturales, además de las formas de vida humana y no-humana.
							<br />
							La construcción de lo común es piedra angular de la acción hidrosocial. Esta acción
							tiene que estar orientada por el conocimiento local y en diálogo con conocimiento
							técnico al servicio de las transformaciones para una transición socioecologica justa.
							<br />
							En el Observatorio CAUCE para la acción hidrosocial buscamos colaborar con personas,
							grupos y organizaciones del agua de diferentes territorios, que defienden las fuentes
							de agua y abordan los desafíos socioecológicos que la escasez hídrica políticamente
							construida está provocando en sus hábitats, para que visibilicen su problema, cuenten
							con información y análisis de datos para orientar sus acciones de manera más efectiva,
							en pos de la construcción de un buen vivir en los territorios.
						</Typography>

						<img className={styles.twWaves} src={waves} />
					</Box>

					<Box pt={10}>
						<Typography variant="h3" align="center" pb={5}>
							MATERIAL AUDIOVISUAL
						</Typography>

						<Carousel
							plugins={[
								'arrows',
								'infinite',
								{
									resolve: autoplayPlugin,
									options: {
										interval: 2000
									}
								}
							]}
							className={styles.twCarousel}
							animationSpeed={1000}
						>
							{images.map((image, index) => (
								<div
									key={index}
									className={styles.twImage}
									style={{ backgroundImage: `url(${image})` }}
								/>
							))}
						</Carousel>
					</Box>
				</Container>
			</Box>
		</>
	)
}
