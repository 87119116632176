import { PaletteOptions } from '@mui/material'

export const COLORS: PaletteOptions = {
	primary: {
		main: '#1464aa',
		contrastText: '#fff'
	},
	secondary: {
		main: '#910f6e'
	},
	divider: '#e7eff7'
}
