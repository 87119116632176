import { createTheme, Palette } from '@mui/material/styles'

import { COLORS } from './colors'

export const createBaseStyles = (darkMode: boolean) =>
	createTheme({
		palette: {
			mode: darkMode ? 'dark' : 'light',
			...COLORS
		},
		typography: {
			fontFamily: ['Barlow Condensed', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(',')
		},
		components: {
			MuiContainer: {
				styleOverrides: {
					root: ({ theme }) => ({
						[theme.breakpoints.up('xl')]: {
							maxWidth: 1500
						}
					})
				}
			},
			MuiButton: {
				styleOverrides: {
					root: () => ({
						padding: '8px 20px',
						borderRadius: '9999px',
						fontSize: '1rem'
					})
				}
			}
		}
	})

export const extendPaletteToHtml = (palette: Palette) => {
	const darkMode = palette.mode === 'dark'

	const html = document.querySelector('html')
	if (darkMode) html?.classList.add('dark')
	else html?.classList.remove('dark')

	const root = document.documentElement

	root.style.setProperty('--color-primary', hexToRgb(palette.primary.main))
	root.style.setProperty('--color-secondary', hexToRgb(palette.secondary.main))
	root.style.setProperty('--color-error', hexToRgb(palette.error.main))
	root.style.setProperty('--color-warning', hexToRgb(palette.warning.main))
	root.style.setProperty('--color-success', hexToRgb(palette.success.main))
	root.style.setProperty('--color-info', hexToRgb(palette.info.main))
	root.style.setProperty('--color-divider', hexToRgb(palette.divider))
}

const hexToRgb = (hex: string) => {
	const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	const [R, G, B] = rgb ? [parseInt(rgb[1], 16), parseInt(rgb[2], 16), parseInt(rgb[3], 16)] : [0, 0, 0]

	return `${R} ${G} ${B}`
}
