interface Config {
	appVersion: string
	appName: string
	port: number
	env: EnvironmentEnum
	isProduction: boolean
	hasuraEndpoint: string
	hasuraSecret: string
}

enum EnvironmentEnum {
	local = 'local',
	develop = 'develop',
	staging = 'staging',
	production = 'production'
}

const {
	REACT_APP_VERSION,
	REACT_APP_NAME,
	REACT_APP_ENV,
	PORT,
	REACT_APP_HASURA_ENDPOINT,
	REACT_APP_HASURA_ADMIN_SECRET
} = process.env as Record<string, string>

export const environment: Config = {
	appName: REACT_APP_NAME,
	appVersion: REACT_APP_VERSION,
	env: (REACT_APP_ENV ?? EnvironmentEnum.local) as EnvironmentEnum,
	isProduction: REACT_APP_ENV === EnvironmentEnum.production,
	port: Number(PORT),
	hasuraEndpoint: REACT_APP_HASURA_ENDPOINT,
	hasuraSecret: REACT_APP_HASURA_ADMIN_SECRET
}
