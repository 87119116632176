import { useCallback } from 'react'

import emailjs from '@emailjs/browser'
import { Box, Button, CircularProgress, Container, TextField, Typography } from '@mui/material'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useInsertContactUsMutation } from 'src/graphql/autogenerate/generated'
import { EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, EMAILJS_API_KEY } from 'src/shared/utils/constants'

import styles from './Participate.module.css'

type Inputs = {
	email: string
	subject: string
	comment: string
}

export const Participate = () => {
	const { register, handleSubmit, reset } = useForm<Inputs>()
	const { executeRecaptcha } = useGoogleReCaptcha()

	const [insertContactUs, { loading }] = useInsertContactUsMutation()

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		await insertContactUs({
			variables: {
				data
			},
			onCompleted: () => {
				alert('Mensaje enviado!')
				reset()
			},
			onError: () => {
				alert('Ocurrió un error, inténtalo más tarde!')
			}
		})
	}

	const handleReCaptchaVerify = useCallback(
		async (data: Inputs) => {
			if (!executeRecaptcha) {
				return
			}
			const token = await executeRecaptcha('submit')
			if (token) onSubmit(data)

			emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, data, EMAILJS_API_KEY)
		},
		[executeRecaptcha]
	)

	return (
		<>
			<Box flex={1} mt={5}>
				<Typography variant="h2" align="center" fontWeight="bold" textAlign="center" color="#fff">
					AGREGA TU MAPA
				</Typography>
				<Typography variant="h4" align="center" mt={2} color="#fff">
					Envíanos tu información y estaremos felices de contactarte
				</Typography>
			</Box>
			<Box flex={1} py={10}>
				<Container>
					<Box display="flex" justifyContent="center">
						<Box className={styles.twContactUs}>
							{loading && (
								<Box className={styles.twLoading}>
									<CircularProgress />
								</Box>
							)}

							<Typography variant="h3" align="justify" textAlign="center" textTransform="uppercase">
								Contáctanos
							</Typography>
							<Box py={{ xs: 4, lg: 8 }} px={4} display="flex" flexDirection="column">
								<form onSubmit={handleSubmit(handleReCaptchaVerify)}>
									<TextField
										fullWidth
										type="email"
										id="email"
										label="Email"
										variant="outlined"
										placeholder="name@email.com"
										required
										inputProps={register('email')}
									/>
									<br />
									<br />
									<TextField
										fullWidth
										type="text"
										id="subject"
										label="Titulo"
										variant="outlined"
										placeholder="¿Cómo podemos ayudarte?"
										required
										inputProps={register('subject')}
									/>
									<br />
									<br />
									<TextField
										fullWidth
										multiline
										id="message"
										label="Mensaje"
										variant="outlined"
										placeholder="Deja un comentario..."
										minRows={4}
										inputProps={register('comment')}
									/>
									<br />
									<br />
									<Button variant="contained" type="submit" color="primary" size="small">
										Enviar Mensaje
									</Button>
								</form>
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>
		</>
	)
}
