import { Routes, Route } from 'react-router-dom'
import { AboutUsContainer } from 'src/app/aboutus/AboutUs.container'
import { CommunitiesContainer } from 'src/app/communities/Communities.container'
import { HomeContainer } from 'src/app/home/Home.container'
import { IdiosincrasiasHidricasContainer } from 'src/app/idiosincrasiasHidricas/IdiosincrasiasHidricas.container'
import { InfoContainer } from 'src/app/information/Info.container'
import { ParticipateContainer } from 'src/app/participate/Participate.container'
import { CARD1, CARD2, CARD3 } from 'src/assets/images'
import { Page } from 'src/shared/types/router'

import { Path } from './routes'

export const Pages: Record<keyof typeof Path, Page> = {
	Home: {
		name: 'HOME',
		path: Path.Home,
		Component: HomeContainer
	},
	About: {
		name: 'NOSOTROS',
		path: Path.About,
		Component: AboutUsContainer
	},
	Communities: {
		name: 'VOCES POR EL AGUA',
		path: Path.Communities,
		Component: CommunitiesContainer,
		background: CARD1
	},
	IdiosincrasiasHidricas: {
		name: 'IDIOSINCRASIAS HÍDRICAS',
		path: Path.IdiosincrasiasHidricas,
		Component: IdiosincrasiasHidricasContainer,
		background: CARD2
	},
	Participate: {
		name: 'AGREGA TU MAPA',
		path: Path.Participate,
		Component: ParticipateContainer,
		background: CARD3
	},
	Information: {
		name: 'INFO',
		path: Path.Information,
		Component: InfoContainer
	}
}

export const Router = () => {
	return (
		<Routes>
			{Object.values(Pages).map(({ path, Component }, i) => (
				<Route key={i + path} path={path} element={<Component />} />
			))}
		</Routes>
	)
}
