import { useState, useMemo } from 'react'

import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { client } from 'src/config/apollo/client'
import { createBaseStyles, extendPaletteToHtml } from 'src/config/styles/baseStyles'
import { Footer } from 'src/shared/components/footer/Footer'

import { Router } from '../config/routers/Router'
import { Header } from '../shared/components'

const AppContent = () => {
	return (
		<BrowserRouter>
			<Header />
			<Router />
			<Footer />
		</BrowserRouter>
	)
}

export const App = () => {
	const [darkMode] = useState(false)
	const theme = useMemo(() => {
		const theme = createBaseStyles(darkMode)
		const { palette } = theme
		extendPaletteToHtml(palette)

		return theme
	}, [darkMode])

	return (
		<ApolloProvider client={client}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<AppContent />
			</ThemeProvider>
		</ApolloProvider>
	)
}

export default App

// comentario prueba
