import { FC, useState } from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuIcon from '@mui/icons-material/Menu'
import TwitterIcon from '@mui/icons-material/Twitter'
import {
	AppBar,
	Container,
	IconButton,
	Toolbar,
	Typography,
	TypographyProps,
	Box,
	Menu,
	MenuItem,
	Button
} from '@mui/material'
import { NavLink } from 'react-router-dom'
import { AboutPages } from 'src/app/aboutus/routers/Router'
import logo from 'src/assets/images/logo.png'
import { Pages } from 'src/config/routers/Router'

import styles from './Header.module.css'
export const Header = () => {
	const [anchorElNav, setAnchorElNav] = useState<HTMLElement>()
	const [anchorElAbout, setAnchorElAbout] = useState<HTMLElement>()

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget)
	const handleCloseNavMenu = () => setAnchorElNav(undefined)

	const handleOpenAboutMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElAbout(event.currentTarget)
		setAnchorElNav(undefined)
	}
	const handleCloseAboutMenu = () => setAnchorElAbout(undefined)

	const getPropsMobile = (name: string, path: string) =>
		name === Pages.About.name
			? {
					endIcon: <KeyboardArrowDownIcon color="primary" />,
					className: styles.twMobileMenuItem,
					component: Button,
					onClick: handleOpenAboutMenu
			  }
			: {
					component: NavLink,
					onClick: handleCloseNavMenu,
					to: path
			  }

	const getPropsDesktop = (name: string, path: string) =>
		name === Pages.About.name
			? {
					endIcon: <KeyboardArrowDownIcon />,
					onClick: handleOpenAboutMenu
			  }
			: {
					component: NavLink,
					onClick: handleCloseNavMenu,
					to: path
			  }

	return (
		<AppBar className={styles.twAppBar} position="static" elevation={0}>
			<Container>
				<Toolbar disableGutters className="justify-between">
					<Title />
					<Box className={styles.twMobile}>
						<IconButton size="large" onClick={handleOpenNavMenu}>
							<MenuIcon />
						</IconButton>
						<Menu
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left'
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left'
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
						>
							{Object.values(Pages).map(
								({ name, path }, i) =>
									name !== Pages.Home.name && (
										<MenuItem key={name + i} {...getPropsMobile(name, path)}>
											<Typography component="span" fontWeight="600">
												{name}
											</Typography>
										</MenuItem>
									)
							)}
							<MenuItem component="a" href="https://twitter.com/todesporelAgua" target="_blank">
								<TwitterIcon color="info" className="hover:animate-wave" />
							</MenuItem>
						</Menu>
					</Box>

					<Box ml={2} className={styles.twDesktop}>
						{Object.values(Pages).map(
							({ name, path }, i) =>
								name !== Pages.Home.name && (
									<Button key={name + i} color="primary" {...getPropsDesktop(name, path)}>
										<Typography component="span" fontWeight="600" color="black">
											{name}
										</Typography>
									</Button>
								)
						)}
						<IconButton color="info" href="https://twitter.com/todesporelAgua" target="_blank">
							<TwitterIcon className="hover:animate-wave" />
						</IconButton>
					</Box>

					<Menu
						anchorEl={anchorElAbout}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
						open={Boolean(anchorElAbout)}
						onClose={handleCloseAboutMenu}
					>
						{Object.values(AboutPages).map(({ name, path }, i) => (
							<MenuItem key={name + i} onClick={handleCloseAboutMenu} component={NavLink} to={path}>
								<Typography component="span" fontWeight="600">
									{name}
								</Typography>
							</MenuItem>
						))}
					</Menu>
				</Toolbar>
			</Container>
		</AppBar>
	)
}

const Title: FC<Pick<TypographyProps, 'className'>> = ({ className }) => (
	<Typography component={NavLink} to={Pages.Home.path} noWrap className={className}>
		<div className={styles.twLogo}>
			<img src={logo} alt="logo" />
		</div>
	</Typography>
)
