import '@brainhubeu/react-carousel/lib/style.css'

import { useEffect, useState } from 'react'

import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material'
import waves from 'src/assets/images/waves.gif'
import { GetInfoQuery, useGetInfoQuery } from 'src/graphql/autogenerate/generated'

import styles from './Info.module.css'

export const Info = () => {
	const { data, loading } = useGetInfoQuery()
	const [info, setInfo] = useState<GetInfoQuery['info']>([])
	useEffect(() => {
		if (!loading) {
			setInfo(data?.info || [])
		}
	}, [loading])

	return (
		<>
			<div className={styles.twPortada}>
				<div className={styles.twBloque}></div>
			</div>
			<Box flex={1} py={10} bgcolor="#fff">
				<Container>
					{!loading ? (
						info.map(({ title, subtitle, description }, i) => (
							<Box key={title} pt={i === 0 ? 0 : 10}>
								<Typography component="h1" variant="h2" align="center" fontWeight="bold">
									{title}
								</Typography>

								{subtitle && (
									<Typography
										component="h1"
										variant="h3"
										align="center"
										fontWeight="bold"
										pt={2}
									>
										{subtitle}
									</Typography>
								)}

								<Typography variant="h5" align="justify" my={5}>
									{description}
								</Typography>
								<img className={styles.twWaves} src={waves} />
							</Box>
						))
					) : (
						<Stack direction="row" justifyContent="center" p={10}>
							<CircularProgress />
						</Stack>
					)}
				</Container>
			</Box>
		</>
	)
}
