import { useState } from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Box, Container, Button, Menu, MenuItem, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { NavLink } from 'react-router-dom'
import { AboutPages } from 'src/app/aboutus/routers/Router'
import logo from 'src/assets/images/logo2.png'
import { Pages } from 'src/config/routers/Router'

import styles from './Footer.module.css'

export const Footer = () => {
	const [anchorElAbout, setAnchorElAbout] = useState<HTMLElement>()

	const handleOpenAboutMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElAbout(event.currentTarget)
	const handleCloseAboutMenu = () => setAnchorElAbout(undefined)

	const getProps = (name: string, path: string) =>
		name === Pages.About.name
			? {
					endIcon: <KeyboardArrowDownIcon />,
					component: Button,
					onClick: handleOpenAboutMenu
			  }
			: {
					component: NavLink,
					to: path
			  }

	return (
		<Box className={styles.twFooter} component="footer" borderTop={4} borderColor="secondary.main">
			<Container>
				<Grid container alignItems="center" mt={1}>
					<Grid item xs={12}>
						<Box display="flex" flexWrap="wrap" justifyContent="center" color="primary.contrastText">
							{Object.values(Pages).map(
								({ name, path }) =>
									name !== Pages.Home.name && (
										<Button color="inherit" key={name} {...getProps(name, path)}>
											{name}
										</Button>
									)
							)}

							<Menu
								anchorEl={anchorElAbout}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right'
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right'
								}}
								open={Boolean(anchorElAbout)}
								onClose={handleCloseAboutMenu}
							>
								{Object.values(AboutPages).map(({ name, path }, i) => (
									<MenuItem
										key={name + i}
										onClick={handleCloseAboutMenu}
										component={NavLink}
										to={path}
									>
										<Typography component="span" fontWeight="600">
											{name}
										</Typography>
									</MenuItem>
								))}
							</Menu>
						</Box>
					</Grid>
					<Grid item xs={12} display="flex" justifyContent="center" mt={10} mb={15}>
						<img className={styles.twLogo} src={logo} alt="logo" />
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}
