import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Participate } from 'src/app/participate/Participate'
import { GOOGLE_RECAPTCHA_V3_KEY } from 'src/shared/utils/constants'

export const ParticipateContainer = () => {
	return (
		<GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_V3_KEY}>
			<Participate />
		</GoogleReCaptchaProvider>
	)
}
