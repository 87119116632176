import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "author" */
export type Author = {
  __typename?: 'author';
  address?: Maybe<Scalars['String']>;
  author_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "author" */
export type Author_Aggregate = {
  __typename?: 'author_aggregate';
  aggregate?: Maybe<Author_Aggregate_Fields>;
  nodes: Array<Author>;
};

/** aggregate fields of "author" */
export type Author_Aggregate_Fields = {
  __typename?: 'author_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Author_Max_Fields>;
  min?: Maybe<Author_Min_Fields>;
};


/** aggregate fields of "author" */
export type Author_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Author_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "author". All fields are combined with a logical 'AND'. */
export type Author_Bool_Exp = {
  _and?: InputMaybe<Array<Author_Bool_Exp>>;
  _not?: InputMaybe<Author_Bool_Exp>;
  _or?: InputMaybe<Array<Author_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  author_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "author" */
export enum Author_Constraint {
  /** unique or primary key constraint on columns "author_id" */
  AuthorPkey = 'author_pkey'
}

/** input type for inserting data into table "author" */
export type Author_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Author_Max_Fields = {
  __typename?: 'author_max_fields';
  address?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Author_Min_Fields = {
  __typename?: 'author_min_fields';
  address?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "author" */
export type Author_Mutation_Response = {
  __typename?: 'author_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Author>;
};

/** on_conflict condition type for table "author" */
export type Author_On_Conflict = {
  constraint: Author_Constraint;
  update_columns?: Array<Author_Update_Column>;
  where?: InputMaybe<Author_Bool_Exp>;
};

/** Ordering options when selecting data from "author". */
export type Author_Order_By = {
  address?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: author */
export type Author_Pk_Columns_Input = {
  author_id: Scalars['uuid'];
};

/** select columns of table "author" */
export enum Author_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "author" */
export type Author_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "author" */
export type Author_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Author_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Author_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "author" */
export enum Author_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Author_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Author_Set_Input>;
  /** filter the rows which have to be updated */
  where: Author_Bool_Exp;
};

/** columns and relationships of "contact_us" */
export type Contact_Us = {
  __typename?: 'contact_us';
  comment: Scalars['String'];
  contact_us_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  subject: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "contact_us" */
export type Contact_Us_Aggregate = {
  __typename?: 'contact_us_aggregate';
  aggregate?: Maybe<Contact_Us_Aggregate_Fields>;
  nodes: Array<Contact_Us>;
};

/** aggregate fields of "contact_us" */
export type Contact_Us_Aggregate_Fields = {
  __typename?: 'contact_us_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Contact_Us_Max_Fields>;
  min?: Maybe<Contact_Us_Min_Fields>;
};


/** aggregate fields of "contact_us" */
export type Contact_Us_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Us_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contact_us". All fields are combined with a logical 'AND'. */
export type Contact_Us_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Us_Bool_Exp>>;
  _not?: InputMaybe<Contact_Us_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Us_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  contact_us_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_us" */
export enum Contact_Us_Constraint {
  /** unique or primary key constraint on columns "contact_us_id" */
  ContactUsPkey = 'contact_us_pkey'
}

/** input type for inserting data into table "contact_us" */
export type Contact_Us_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  contact_us_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Contact_Us_Max_Fields = {
  __typename?: 'contact_us_max_fields';
  comment?: Maybe<Scalars['String']>;
  contact_us_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Contact_Us_Min_Fields = {
  __typename?: 'contact_us_min_fields';
  comment?: Maybe<Scalars['String']>;
  contact_us_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "contact_us" */
export type Contact_Us_Mutation_Response = {
  __typename?: 'contact_us_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Us>;
};

/** on_conflict condition type for table "contact_us" */
export type Contact_Us_On_Conflict = {
  constraint: Contact_Us_Constraint;
  update_columns?: Array<Contact_Us_Update_Column>;
  where?: InputMaybe<Contact_Us_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_us". */
export type Contact_Us_Order_By = {
  comment?: InputMaybe<Order_By>;
  contact_us_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_us */
export type Contact_Us_Pk_Columns_Input = {
  contact_us_id: Scalars['uuid'];
};

/** select columns of table "contact_us" */
export enum Contact_Us_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  ContactUsId = 'contact_us_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contact_us" */
export type Contact_Us_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  contact_us_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "contact_us" */
export type Contact_Us_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Us_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Us_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  contact_us_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "contact_us" */
export enum Contact_Us_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  ContactUsId = 'contact_us_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Contact_Us_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Us_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Us_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "entry" */
export type Entry = {
  __typename?: 'entry';
  audio_path?: Maybe<Scalars['String']>;
  author_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  entry_id: Scalars['uuid'];
  image_path?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  video_path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "entry" */
export type Entry_Aggregate = {
  __typename?: 'entry_aggregate';
  aggregate?: Maybe<Entry_Aggregate_Fields>;
  nodes: Array<Entry>;
};

/** aggregate fields of "entry" */
export type Entry_Aggregate_Fields = {
  __typename?: 'entry_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Entry_Max_Fields>;
  min?: Maybe<Entry_Min_Fields>;
};


/** aggregate fields of "entry" */
export type Entry_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Entry_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "entry". All fields are combined with a logical 'AND'. */
export type Entry_Bool_Exp = {
  _and?: InputMaybe<Array<Entry_Bool_Exp>>;
  _not?: InputMaybe<Entry_Bool_Exp>;
  _or?: InputMaybe<Array<Entry_Bool_Exp>>;
  audio_path?: InputMaybe<String_Comparison_Exp>;
  author_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  entry_id?: InputMaybe<Uuid_Comparison_Exp>;
  image_path?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  video_path?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "entry" */
export enum Entry_Constraint {
  /** unique or primary key constraint on columns "entry_id" */
  EntryPkey = 'entry_pkey'
}

/** input type for inserting data into table "entry" */
export type Entry_Insert_Input = {
  audio_path?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entry_id?: InputMaybe<Scalars['uuid']>;
  image_path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  video_path?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Entry_Max_Fields = {
  __typename?: 'entry_max_fields';
  audio_path?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  entry_id?: Maybe<Scalars['uuid']>;
  image_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Entry_Min_Fields = {
  __typename?: 'entry_min_fields';
  audio_path?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  entry_id?: Maybe<Scalars['uuid']>;
  image_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "entry" */
export type Entry_Mutation_Response = {
  __typename?: 'entry_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Entry>;
};

/** on_conflict condition type for table "entry" */
export type Entry_On_Conflict = {
  constraint: Entry_Constraint;
  update_columns?: Array<Entry_Update_Column>;
  where?: InputMaybe<Entry_Bool_Exp>;
};

/** Ordering options when selecting data from "entry". */
export type Entry_Order_By = {
  audio_path?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  entry_id?: InputMaybe<Order_By>;
  image_path?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video_path?: InputMaybe<Order_By>;
};

/** primary key columns input for table: entry */
export type Entry_Pk_Columns_Input = {
  entry_id: Scalars['uuid'];
};

/** select columns of table "entry" */
export enum Entry_Select_Column {
  /** column name */
  AudioPath = 'audio_path',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EntryId = 'entry_id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoPath = 'video_path'
}

/** input type for updating data in table "entry" */
export type Entry_Set_Input = {
  audio_path?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entry_id?: InputMaybe<Scalars['uuid']>;
  image_path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  video_path?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "entry" */
export type Entry_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Entry_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Entry_Stream_Cursor_Value_Input = {
  audio_path?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entry_id?: InputMaybe<Scalars['uuid']>;
  image_path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  video_path?: InputMaybe<Scalars['String']>;
};

/** update columns of table "entry" */
export enum Entry_Update_Column {
  /** column name */
  AudioPath = 'audio_path',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EntryId = 'entry_id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoPath = 'video_path'
}

export type Entry_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Entry_Set_Input>;
  /** filter the rows which have to be updated */
  where: Entry_Bool_Exp;
};

/** columns and relationships of "info" */
export type Info = {
  __typename?: 'info';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  info_id: Scalars['uuid'];
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "info" */
export type Info_Aggregate = {
  __typename?: 'info_aggregate';
  aggregate?: Maybe<Info_Aggregate_Fields>;
  nodes: Array<Info>;
};

/** aggregate fields of "info" */
export type Info_Aggregate_Fields = {
  __typename?: 'info_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Info_Max_Fields>;
  min?: Maybe<Info_Min_Fields>;
};


/** aggregate fields of "info" */
export type Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "info". All fields are combined with a logical 'AND'. */
export type Info_Bool_Exp = {
  _and?: InputMaybe<Array<Info_Bool_Exp>>;
  _not?: InputMaybe<Info_Bool_Exp>;
  _or?: InputMaybe<Array<Info_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  info_id?: InputMaybe<Uuid_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "info" */
export enum Info_Constraint {
  /** unique or primary key constraint on columns "info_id" */
  InfoPkey = 'info_pkey'
}

/** input type for inserting data into table "info" */
export type Info_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  info_id?: InputMaybe<Scalars['uuid']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Info_Max_Fields = {
  __typename?: 'info_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  info_id?: Maybe<Scalars['uuid']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Info_Min_Fields = {
  __typename?: 'info_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  info_id?: Maybe<Scalars['uuid']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "info" */
export type Info_Mutation_Response = {
  __typename?: 'info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Info>;
};

/** on_conflict condition type for table "info" */
export type Info_On_Conflict = {
  constraint: Info_Constraint;
  update_columns?: Array<Info_Update_Column>;
  where?: InputMaybe<Info_Bool_Exp>;
};

/** Ordering options when selecting data from "info". */
export type Info_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  info_id?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: info */
export type Info_Pk_Columns_Input = {
  info_id: Scalars['uuid'];
};

/** select columns of table "info" */
export enum Info_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  InfoId = 'info_id',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "info" */
export type Info_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  info_id?: InputMaybe<Scalars['uuid']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "info" */
export type Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Info_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  info_id?: InputMaybe<Scalars['uuid']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "info" */
export enum Info_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  InfoId = 'info_id',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Info_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Info_Set_Input>;
  /** filter the rows which have to be updated */
  where: Info_Bool_Exp;
};

/** columns and relationships of "kml" */
export type Kml = {
  __typename?: 'kml';
  active: Scalars['Boolean'];
  author_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  kml_id: Scalars['uuid'];
  resource_path: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "kml" */
export type Kml_Aggregate = {
  __typename?: 'kml_aggregate';
  aggregate?: Maybe<Kml_Aggregate_Fields>;
  nodes: Array<Kml>;
};

/** aggregate fields of "kml" */
export type Kml_Aggregate_Fields = {
  __typename?: 'kml_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kml_Max_Fields>;
  min?: Maybe<Kml_Min_Fields>;
};


/** aggregate fields of "kml" */
export type Kml_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kml_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "kml". All fields are combined with a logical 'AND'. */
export type Kml_Bool_Exp = {
  _and?: InputMaybe<Array<Kml_Bool_Exp>>;
  _not?: InputMaybe<Kml_Bool_Exp>;
  _or?: InputMaybe<Array<Kml_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  author_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  kml_id?: InputMaybe<Uuid_Comparison_Exp>;
  resource_path?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "kml" */
export enum Kml_Constraint {
  /** unique or primary key constraint on columns "kml_id" */
  KmlPkey = 'kml_pkey'
}

/** input type for inserting data into table "kml" */
export type Kml_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  kml_id?: InputMaybe<Scalars['uuid']>;
  resource_path?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Kml_Max_Fields = {
  __typename?: 'kml_max_fields';
  author_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  kml_id?: Maybe<Scalars['uuid']>;
  resource_path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Kml_Min_Fields = {
  __typename?: 'kml_min_fields';
  author_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  kml_id?: Maybe<Scalars['uuid']>;
  resource_path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "kml" */
export type Kml_Mutation_Response = {
  __typename?: 'kml_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kml>;
};

/** on_conflict condition type for table "kml" */
export type Kml_On_Conflict = {
  constraint: Kml_Constraint;
  update_columns?: Array<Kml_Update_Column>;
  where?: InputMaybe<Kml_Bool_Exp>;
};

/** Ordering options when selecting data from "kml". */
export type Kml_Order_By = {
  active?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  kml_id?: InputMaybe<Order_By>;
  resource_path?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kml */
export type Kml_Pk_Columns_Input = {
  kml_id: Scalars['uuid'];
};

/** select columns of table "kml" */
export enum Kml_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KmlId = 'kml_id',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "kml" */
export type Kml_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  kml_id?: InputMaybe<Scalars['uuid']>;
  resource_path?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "kml" */
export type Kml_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kml_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kml_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  kml_id?: InputMaybe<Scalars['uuid']>;
  resource_path?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "kml" */
export enum Kml_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KmlId = 'kml_id',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Kml_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kml_Set_Input>;
  /** filter the rows which have to be updated */
  where: Kml_Bool_Exp;
};

/** columns and relationships of "marker" */
export type Marker = {
  __typename?: 'marker';
  active: Scalars['Boolean'];
  author_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  entrevistados?: Maybe<Scalars['String']>;
  lat: Scalars['numeric'];
  lng: Scalars['numeric'];
  marker_id: Scalars['uuid'];
  page: Scalars['String'];
  resource_path?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  videos?: Maybe<Scalars['String']>;
};

/** aggregated selection of "marker" */
export type Marker_Aggregate = {
  __typename?: 'marker_aggregate';
  aggregate?: Maybe<Marker_Aggregate_Fields>;
  nodes: Array<Marker>;
};

/** aggregate fields of "marker" */
export type Marker_Aggregate_Fields = {
  __typename?: 'marker_aggregate_fields';
  avg?: Maybe<Marker_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Marker_Max_Fields>;
  min?: Maybe<Marker_Min_Fields>;
  stddev?: Maybe<Marker_Stddev_Fields>;
  stddev_pop?: Maybe<Marker_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marker_Stddev_Samp_Fields>;
  sum?: Maybe<Marker_Sum_Fields>;
  var_pop?: Maybe<Marker_Var_Pop_Fields>;
  var_samp?: Maybe<Marker_Var_Samp_Fields>;
  variance?: Maybe<Marker_Variance_Fields>;
};


/** aggregate fields of "marker" */
export type Marker_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Marker_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Marker_Avg_Fields = {
  __typename?: 'marker_avg_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "marker". All fields are combined with a logical 'AND'. */
export type Marker_Bool_Exp = {
  _and?: InputMaybe<Array<Marker_Bool_Exp>>;
  _not?: InputMaybe<Marker_Bool_Exp>;
  _or?: InputMaybe<Array<Marker_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  author_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  entrevistados?: InputMaybe<String_Comparison_Exp>;
  lat?: InputMaybe<Numeric_Comparison_Exp>;
  lng?: InputMaybe<Numeric_Comparison_Exp>;
  marker_id?: InputMaybe<Uuid_Comparison_Exp>;
  page?: InputMaybe<String_Comparison_Exp>;
  resource_path?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  videos?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "marker" */
export enum Marker_Constraint {
  /** unique or primary key constraint on columns "marker_id" */
  MarkerPkey = 'marker_pkey'
}

/** input type for incrementing numeric columns in table "marker" */
export type Marker_Inc_Input = {
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "marker" */
export type Marker_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entrevistados?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  marker_id?: InputMaybe<Scalars['uuid']>;
  page?: InputMaybe<Scalars['String']>;
  resource_path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  videos?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Marker_Max_Fields = {
  __typename?: 'marker_max_fields';
  author_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  entrevistados?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  marker_id?: Maybe<Scalars['uuid']>;
  page?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  videos?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Marker_Min_Fields = {
  __typename?: 'marker_min_fields';
  author_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  entrevistados?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  marker_id?: Maybe<Scalars['uuid']>;
  page?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  videos?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "marker" */
export type Marker_Mutation_Response = {
  __typename?: 'marker_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Marker>;
};

/** on_conflict condition type for table "marker" */
export type Marker_On_Conflict = {
  constraint: Marker_Constraint;
  update_columns?: Array<Marker_Update_Column>;
  where?: InputMaybe<Marker_Bool_Exp>;
};

/** Ordering options when selecting data from "marker". */
export type Marker_Order_By = {
  active?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  entrevistados?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  marker_id?: InputMaybe<Order_By>;
  page?: InputMaybe<Order_By>;
  resource_path?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  videos?: InputMaybe<Order_By>;
};

/** primary key columns input for table: marker */
export type Marker_Pk_Columns_Input = {
  marker_id: Scalars['uuid'];
};

/** select columns of table "marker" */
export enum Marker_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Entrevistados = 'entrevistados',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  MarkerId = 'marker_id',
  /** column name */
  Page = 'page',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Videos = 'videos'
}

/** input type for updating data in table "marker" */
export type Marker_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entrevistados?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  marker_id?: InputMaybe<Scalars['uuid']>;
  page?: InputMaybe<Scalars['String']>;
  resource_path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  videos?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Marker_Stddev_Fields = {
  __typename?: 'marker_stddev_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Marker_Stddev_Pop_Fields = {
  __typename?: 'marker_stddev_pop_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Marker_Stddev_Samp_Fields = {
  __typename?: 'marker_stddev_samp_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "marker" */
export type Marker_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Marker_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Marker_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entrevistados?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['numeric']>;
  lng?: InputMaybe<Scalars['numeric']>;
  marker_id?: InputMaybe<Scalars['uuid']>;
  page?: InputMaybe<Scalars['String']>;
  resource_path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  videos?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Marker_Sum_Fields = {
  __typename?: 'marker_sum_fields';
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** update columns of table "marker" */
export enum Marker_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Entrevistados = 'entrevistados',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  MarkerId = 'marker_id',
  /** column name */
  Page = 'page',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Videos = 'videos'
}

export type Marker_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Marker_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Marker_Set_Input>;
  /** filter the rows which have to be updated */
  where: Marker_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Marker_Var_Pop_Fields = {
  __typename?: 'marker_var_pop_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Marker_Var_Samp_Fields = {
  __typename?: 'marker_var_samp_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Marker_Variance_Fields = {
  __typename?: 'marker_variance_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "author" */
  delete_author?: Maybe<Author_Mutation_Response>;
  /** delete single row from the table: "author" */
  delete_author_by_pk?: Maybe<Author>;
  /** delete data from the table: "contact_us" */
  delete_contact_us?: Maybe<Contact_Us_Mutation_Response>;
  /** delete single row from the table: "contact_us" */
  delete_contact_us_by_pk?: Maybe<Contact_Us>;
  /** delete data from the table: "entry" */
  delete_entry?: Maybe<Entry_Mutation_Response>;
  /** delete single row from the table: "entry" */
  delete_entry_by_pk?: Maybe<Entry>;
  /** delete data from the table: "info" */
  delete_info?: Maybe<Info_Mutation_Response>;
  /** delete single row from the table: "info" */
  delete_info_by_pk?: Maybe<Info>;
  /** delete data from the table: "kml" */
  delete_kml?: Maybe<Kml_Mutation_Response>;
  /** delete single row from the table: "kml" */
  delete_kml_by_pk?: Maybe<Kml>;
  /** delete data from the table: "marker" */
  delete_marker?: Maybe<Marker_Mutation_Response>;
  /** delete single row from the table: "marker" */
  delete_marker_by_pk?: Maybe<Marker>;
  /** delete data from the table: "page" */
  delete_page?: Maybe<Page_Mutation_Response>;
  /** delete single row from the table: "page" */
  delete_page_by_pk?: Maybe<Page>;
  /** delete data from the table: "team" */
  delete_team?: Maybe<Team_Mutation_Response>;
  /** delete single row from the table: "team" */
  delete_team_by_pk?: Maybe<Team>;
  /** insert data into the table: "author" */
  insert_author?: Maybe<Author_Mutation_Response>;
  /** insert a single row into the table: "author" */
  insert_author_one?: Maybe<Author>;
  /** insert data into the table: "contact_us" */
  insert_contact_us?: Maybe<Contact_Us_Mutation_Response>;
  /** insert a single row into the table: "contact_us" */
  insert_contact_us_one?: Maybe<Contact_Us>;
  /** insert data into the table: "entry" */
  insert_entry?: Maybe<Entry_Mutation_Response>;
  /** insert a single row into the table: "entry" */
  insert_entry_one?: Maybe<Entry>;
  /** insert data into the table: "info" */
  insert_info?: Maybe<Info_Mutation_Response>;
  /** insert a single row into the table: "info" */
  insert_info_one?: Maybe<Info>;
  /** insert data into the table: "kml" */
  insert_kml?: Maybe<Kml_Mutation_Response>;
  /** insert a single row into the table: "kml" */
  insert_kml_one?: Maybe<Kml>;
  /** insert data into the table: "marker" */
  insert_marker?: Maybe<Marker_Mutation_Response>;
  /** insert a single row into the table: "marker" */
  insert_marker_one?: Maybe<Marker>;
  /** insert data into the table: "page" */
  insert_page?: Maybe<Page_Mutation_Response>;
  /** insert a single row into the table: "page" */
  insert_page_one?: Maybe<Page>;
  /** insert data into the table: "team" */
  insert_team?: Maybe<Team_Mutation_Response>;
  /** insert a single row into the table: "team" */
  insert_team_one?: Maybe<Team>;
  /** update data of the table: "author" */
  update_author?: Maybe<Author_Mutation_Response>;
  /** update single row of the table: "author" */
  update_author_by_pk?: Maybe<Author>;
  /** update multiples rows of table: "author" */
  update_author_many?: Maybe<Array<Maybe<Author_Mutation_Response>>>;
  /** update data of the table: "contact_us" */
  update_contact_us?: Maybe<Contact_Us_Mutation_Response>;
  /** update single row of the table: "contact_us" */
  update_contact_us_by_pk?: Maybe<Contact_Us>;
  /** update multiples rows of table: "contact_us" */
  update_contact_us_many?: Maybe<Array<Maybe<Contact_Us_Mutation_Response>>>;
  /** update data of the table: "entry" */
  update_entry?: Maybe<Entry_Mutation_Response>;
  /** update single row of the table: "entry" */
  update_entry_by_pk?: Maybe<Entry>;
  /** update multiples rows of table: "entry" */
  update_entry_many?: Maybe<Array<Maybe<Entry_Mutation_Response>>>;
  /** update data of the table: "info" */
  update_info?: Maybe<Info_Mutation_Response>;
  /** update single row of the table: "info" */
  update_info_by_pk?: Maybe<Info>;
  /** update multiples rows of table: "info" */
  update_info_many?: Maybe<Array<Maybe<Info_Mutation_Response>>>;
  /** update data of the table: "kml" */
  update_kml?: Maybe<Kml_Mutation_Response>;
  /** update single row of the table: "kml" */
  update_kml_by_pk?: Maybe<Kml>;
  /** update multiples rows of table: "kml" */
  update_kml_many?: Maybe<Array<Maybe<Kml_Mutation_Response>>>;
  /** update data of the table: "marker" */
  update_marker?: Maybe<Marker_Mutation_Response>;
  /** update single row of the table: "marker" */
  update_marker_by_pk?: Maybe<Marker>;
  /** update multiples rows of table: "marker" */
  update_marker_many?: Maybe<Array<Maybe<Marker_Mutation_Response>>>;
  /** update data of the table: "page" */
  update_page?: Maybe<Page_Mutation_Response>;
  /** update single row of the table: "page" */
  update_page_by_pk?: Maybe<Page>;
  /** update multiples rows of table: "page" */
  update_page_many?: Maybe<Array<Maybe<Page_Mutation_Response>>>;
  /** update data of the table: "team" */
  update_team?: Maybe<Team_Mutation_Response>;
  /** update single row of the table: "team" */
  update_team_by_pk?: Maybe<Team>;
  /** update multiples rows of table: "team" */
  update_team_many?: Maybe<Array<Maybe<Team_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_AuthorArgs = {
  where: Author_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Author_By_PkArgs = {
  author_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_UsArgs = {
  where: Contact_Us_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Us_By_PkArgs = {
  contact_us_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EntryArgs = {
  where: Entry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Entry_By_PkArgs = {
  entry_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InfoArgs = {
  where: Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Info_By_PkArgs = {
  info_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_KmlArgs = {
  where: Kml_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kml_By_PkArgs = {
  kml_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MarkerArgs = {
  where: Marker_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Marker_By_PkArgs = {
  marker_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PageArgs = {
  where: Page_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Page_By_PkArgs = {
  page_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TeamArgs = {
  where: Team_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_By_PkArgs = {
  team_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_AuthorArgs = {
  objects: Array<Author_Insert_Input>;
  on_conflict?: InputMaybe<Author_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Author_OneArgs = {
  object: Author_Insert_Input;
  on_conflict?: InputMaybe<Author_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_UsArgs = {
  objects: Array<Contact_Us_Insert_Input>;
  on_conflict?: InputMaybe<Contact_Us_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Us_OneArgs = {
  object: Contact_Us_Insert_Input;
  on_conflict?: InputMaybe<Contact_Us_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EntryArgs = {
  objects: Array<Entry_Insert_Input>;
  on_conflict?: InputMaybe<Entry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Entry_OneArgs = {
  object: Entry_Insert_Input;
  on_conflict?: InputMaybe<Entry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InfoArgs = {
  objects: Array<Info_Insert_Input>;
  on_conflict?: InputMaybe<Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Info_OneArgs = {
  object: Info_Insert_Input;
  on_conflict?: InputMaybe<Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_KmlArgs = {
  objects: Array<Kml_Insert_Input>;
  on_conflict?: InputMaybe<Kml_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kml_OneArgs = {
  object: Kml_Insert_Input;
  on_conflict?: InputMaybe<Kml_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MarkerArgs = {
  objects: Array<Marker_Insert_Input>;
  on_conflict?: InputMaybe<Marker_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Marker_OneArgs = {
  object: Marker_Insert_Input;
  on_conflict?: InputMaybe<Marker_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PageArgs = {
  objects: Array<Page_Insert_Input>;
  on_conflict?: InputMaybe<Page_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Page_OneArgs = {
  object: Page_Insert_Input;
  on_conflict?: InputMaybe<Page_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamArgs = {
  objects: Array<Team_Insert_Input>;
  on_conflict?: InputMaybe<Team_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_OneArgs = {
  object: Team_Insert_Input;
  on_conflict?: InputMaybe<Team_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_AuthorArgs = {
  _set?: InputMaybe<Author_Set_Input>;
  where: Author_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Author_By_PkArgs = {
  _set?: InputMaybe<Author_Set_Input>;
  pk_columns: Author_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Author_ManyArgs = {
  updates: Array<Author_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_UsArgs = {
  _set?: InputMaybe<Contact_Us_Set_Input>;
  where: Contact_Us_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Us_By_PkArgs = {
  _set?: InputMaybe<Contact_Us_Set_Input>;
  pk_columns: Contact_Us_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Us_ManyArgs = {
  updates: Array<Contact_Us_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EntryArgs = {
  _set?: InputMaybe<Entry_Set_Input>;
  where: Entry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Entry_By_PkArgs = {
  _set?: InputMaybe<Entry_Set_Input>;
  pk_columns: Entry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Entry_ManyArgs = {
  updates: Array<Entry_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InfoArgs = {
  _set?: InputMaybe<Info_Set_Input>;
  where: Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Info_By_PkArgs = {
  _set?: InputMaybe<Info_Set_Input>;
  pk_columns: Info_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Info_ManyArgs = {
  updates: Array<Info_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_KmlArgs = {
  _set?: InputMaybe<Kml_Set_Input>;
  where: Kml_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kml_By_PkArgs = {
  _set?: InputMaybe<Kml_Set_Input>;
  pk_columns: Kml_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kml_ManyArgs = {
  updates: Array<Kml_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MarkerArgs = {
  _inc?: InputMaybe<Marker_Inc_Input>;
  _set?: InputMaybe<Marker_Set_Input>;
  where: Marker_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Marker_By_PkArgs = {
  _inc?: InputMaybe<Marker_Inc_Input>;
  _set?: InputMaybe<Marker_Set_Input>;
  pk_columns: Marker_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Marker_ManyArgs = {
  updates: Array<Marker_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PageArgs = {
  _set?: InputMaybe<Page_Set_Input>;
  where: Page_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Page_By_PkArgs = {
  _set?: InputMaybe<Page_Set_Input>;
  pk_columns: Page_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Page_ManyArgs = {
  updates: Array<Page_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TeamArgs = {
  _set?: InputMaybe<Team_Set_Input>;
  where: Team_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_By_PkArgs = {
  _set?: InputMaybe<Team_Set_Input>;
  pk_columns: Team_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_ManyArgs = {
  updates: Array<Team_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "page" */
export type Page = {
  __typename?: 'page';
  created_at: Scalars['timestamptz'];
  name: Scalars['String'];
  page_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "page" */
export type Page_Aggregate = {
  __typename?: 'page_aggregate';
  aggregate?: Maybe<Page_Aggregate_Fields>;
  nodes: Array<Page>;
};

/** aggregate fields of "page" */
export type Page_Aggregate_Fields = {
  __typename?: 'page_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Page_Max_Fields>;
  min?: Maybe<Page_Min_Fields>;
};


/** aggregate fields of "page" */
export type Page_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Page_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "page". All fields are combined with a logical 'AND'. */
export type Page_Bool_Exp = {
  _and?: InputMaybe<Array<Page_Bool_Exp>>;
  _not?: InputMaybe<Page_Bool_Exp>;
  _or?: InputMaybe<Array<Page_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  page_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "page" */
export enum Page_Constraint {
  /** unique or primary key constraint on columns "page_id" */
  PagePkey = 'page_pkey'
}

/** input type for inserting data into table "page" */
export type Page_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  page_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Page_Max_Fields = {
  __typename?: 'page_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  page_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Page_Min_Fields = {
  __typename?: 'page_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  page_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "page" */
export type Page_Mutation_Response = {
  __typename?: 'page_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Page>;
};

/** on_conflict condition type for table "page" */
export type Page_On_Conflict = {
  constraint: Page_Constraint;
  update_columns?: Array<Page_Update_Column>;
  where?: InputMaybe<Page_Bool_Exp>;
};

/** Ordering options when selecting data from "page". */
export type Page_Order_By = {
  created_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  page_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: page */
export type Page_Pk_Columns_Input = {
  page_id: Scalars['uuid'];
};

/** select columns of table "page" */
export enum Page_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  PageId = 'page_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "page" */
export type Page_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  page_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "page" */
export type Page_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Page_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Page_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  page_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "page" */
export enum Page_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  PageId = 'page_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Page_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Page_Set_Input>;
  /** filter the rows which have to be updated */
  where: Page_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "author" */
  author: Array<Author>;
  /** fetch aggregated fields from the table: "author" */
  author_aggregate: Author_Aggregate;
  /** fetch data from the table: "author" using primary key columns */
  author_by_pk?: Maybe<Author>;
  /** fetch data from the table: "contact_us" */
  contact_us: Array<Contact_Us>;
  /** fetch aggregated fields from the table: "contact_us" */
  contact_us_aggregate: Contact_Us_Aggregate;
  /** fetch data from the table: "contact_us" using primary key columns */
  contact_us_by_pk?: Maybe<Contact_Us>;
  /** fetch data from the table: "entry" */
  entry: Array<Entry>;
  /** fetch aggregated fields from the table: "entry" */
  entry_aggregate: Entry_Aggregate;
  /** fetch data from the table: "entry" using primary key columns */
  entry_by_pk?: Maybe<Entry>;
  /** fetch data from the table: "info" */
  info: Array<Info>;
  /** fetch aggregated fields from the table: "info" */
  info_aggregate: Info_Aggregate;
  /** fetch data from the table: "info" using primary key columns */
  info_by_pk?: Maybe<Info>;
  /** fetch data from the table: "kml" */
  kml: Array<Kml>;
  /** fetch aggregated fields from the table: "kml" */
  kml_aggregate: Kml_Aggregate;
  /** fetch data from the table: "kml" using primary key columns */
  kml_by_pk?: Maybe<Kml>;
  /** fetch data from the table: "marker" */
  marker: Array<Marker>;
  /** fetch aggregated fields from the table: "marker" */
  marker_aggregate: Marker_Aggregate;
  /** fetch data from the table: "marker" using primary key columns */
  marker_by_pk?: Maybe<Marker>;
  /** fetch data from the table: "page" */
  page: Array<Page>;
  /** fetch aggregated fields from the table: "page" */
  page_aggregate: Page_Aggregate;
  /** fetch data from the table: "page" using primary key columns */
  page_by_pk?: Maybe<Page>;
  /** fetch data from the table: "team" */
  team: Array<Team>;
  /** fetch aggregated fields from the table: "team" */
  team_aggregate: Team_Aggregate;
  /** fetch data from the table: "team" using primary key columns */
  team_by_pk?: Maybe<Team>;
};


export type Query_RootAuthorArgs = {
  distinct_on?: InputMaybe<Array<Author_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Author_Order_By>>;
  where?: InputMaybe<Author_Bool_Exp>;
};


export type Query_RootAuthor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Author_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Author_Order_By>>;
  where?: InputMaybe<Author_Bool_Exp>;
};


export type Query_RootAuthor_By_PkArgs = {
  author_id: Scalars['uuid'];
};


export type Query_RootContact_UsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Us_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Us_Order_By>>;
  where?: InputMaybe<Contact_Us_Bool_Exp>;
};


export type Query_RootContact_Us_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Us_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Us_Order_By>>;
  where?: InputMaybe<Contact_Us_Bool_Exp>;
};


export type Query_RootContact_Us_By_PkArgs = {
  contact_us_id: Scalars['uuid'];
};


export type Query_RootEntryArgs = {
  distinct_on?: InputMaybe<Array<Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entry_Order_By>>;
  where?: InputMaybe<Entry_Bool_Exp>;
};


export type Query_RootEntry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entry_Order_By>>;
  where?: InputMaybe<Entry_Bool_Exp>;
};


export type Query_RootEntry_By_PkArgs = {
  entry_id: Scalars['uuid'];
};


export type Query_RootInfoArgs = {
  distinct_on?: InputMaybe<Array<Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Info_Order_By>>;
  where?: InputMaybe<Info_Bool_Exp>;
};


export type Query_RootInfo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Info_Order_By>>;
  where?: InputMaybe<Info_Bool_Exp>;
};


export type Query_RootInfo_By_PkArgs = {
  info_id: Scalars['uuid'];
};


export type Query_RootKmlArgs = {
  distinct_on?: InputMaybe<Array<Kml_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kml_Order_By>>;
  where?: InputMaybe<Kml_Bool_Exp>;
};


export type Query_RootKml_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kml_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kml_Order_By>>;
  where?: InputMaybe<Kml_Bool_Exp>;
};


export type Query_RootKml_By_PkArgs = {
  kml_id: Scalars['uuid'];
};


export type Query_RootMarkerArgs = {
  distinct_on?: InputMaybe<Array<Marker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Marker_Order_By>>;
  where?: InputMaybe<Marker_Bool_Exp>;
};


export type Query_RootMarker_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Marker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Marker_Order_By>>;
  where?: InputMaybe<Marker_Bool_Exp>;
};


export type Query_RootMarker_By_PkArgs = {
  marker_id: Scalars['uuid'];
};


export type Query_RootPageArgs = {
  distinct_on?: InputMaybe<Array<Page_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Page_Order_By>>;
  where?: InputMaybe<Page_Bool_Exp>;
};


export type Query_RootPage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Page_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Page_Order_By>>;
  where?: InputMaybe<Page_Bool_Exp>;
};


export type Query_RootPage_By_PkArgs = {
  page_id: Scalars['uuid'];
};


export type Query_RootTeamArgs = {
  distinct_on?: InputMaybe<Array<Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Order_By>>;
  where?: InputMaybe<Team_Bool_Exp>;
};


export type Query_RootTeam_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Order_By>>;
  where?: InputMaybe<Team_Bool_Exp>;
};


export type Query_RootTeam_By_PkArgs = {
  team_id: Scalars['uuid'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "author" */
  author: Array<Author>;
  /** fetch aggregated fields from the table: "author" */
  author_aggregate: Author_Aggregate;
  /** fetch data from the table: "author" using primary key columns */
  author_by_pk?: Maybe<Author>;
  /** fetch data from the table in a streaming manner: "author" */
  author_stream: Array<Author>;
  /** fetch data from the table: "contact_us" */
  contact_us: Array<Contact_Us>;
  /** fetch aggregated fields from the table: "contact_us" */
  contact_us_aggregate: Contact_Us_Aggregate;
  /** fetch data from the table: "contact_us" using primary key columns */
  contact_us_by_pk?: Maybe<Contact_Us>;
  /** fetch data from the table in a streaming manner: "contact_us" */
  contact_us_stream: Array<Contact_Us>;
  /** fetch data from the table: "entry" */
  entry: Array<Entry>;
  /** fetch aggregated fields from the table: "entry" */
  entry_aggregate: Entry_Aggregate;
  /** fetch data from the table: "entry" using primary key columns */
  entry_by_pk?: Maybe<Entry>;
  /** fetch data from the table in a streaming manner: "entry" */
  entry_stream: Array<Entry>;
  /** fetch data from the table: "info" */
  info: Array<Info>;
  /** fetch aggregated fields from the table: "info" */
  info_aggregate: Info_Aggregate;
  /** fetch data from the table: "info" using primary key columns */
  info_by_pk?: Maybe<Info>;
  /** fetch data from the table in a streaming manner: "info" */
  info_stream: Array<Info>;
  /** fetch data from the table: "kml" */
  kml: Array<Kml>;
  /** fetch aggregated fields from the table: "kml" */
  kml_aggregate: Kml_Aggregate;
  /** fetch data from the table: "kml" using primary key columns */
  kml_by_pk?: Maybe<Kml>;
  /** fetch data from the table in a streaming manner: "kml" */
  kml_stream: Array<Kml>;
  /** fetch data from the table: "marker" */
  marker: Array<Marker>;
  /** fetch aggregated fields from the table: "marker" */
  marker_aggregate: Marker_Aggregate;
  /** fetch data from the table: "marker" using primary key columns */
  marker_by_pk?: Maybe<Marker>;
  /** fetch data from the table in a streaming manner: "marker" */
  marker_stream: Array<Marker>;
  /** fetch data from the table: "page" */
  page: Array<Page>;
  /** fetch aggregated fields from the table: "page" */
  page_aggregate: Page_Aggregate;
  /** fetch data from the table: "page" using primary key columns */
  page_by_pk?: Maybe<Page>;
  /** fetch data from the table in a streaming manner: "page" */
  page_stream: Array<Page>;
  /** fetch data from the table: "team" */
  team: Array<Team>;
  /** fetch aggregated fields from the table: "team" */
  team_aggregate: Team_Aggregate;
  /** fetch data from the table: "team" using primary key columns */
  team_by_pk?: Maybe<Team>;
  /** fetch data from the table in a streaming manner: "team" */
  team_stream: Array<Team>;
};


export type Subscription_RootAuthorArgs = {
  distinct_on?: InputMaybe<Array<Author_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Author_Order_By>>;
  where?: InputMaybe<Author_Bool_Exp>;
};


export type Subscription_RootAuthor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Author_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Author_Order_By>>;
  where?: InputMaybe<Author_Bool_Exp>;
};


export type Subscription_RootAuthor_By_PkArgs = {
  author_id: Scalars['uuid'];
};


export type Subscription_RootAuthor_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Author_Stream_Cursor_Input>>;
  where?: InputMaybe<Author_Bool_Exp>;
};


export type Subscription_RootContact_UsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Us_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Us_Order_By>>;
  where?: InputMaybe<Contact_Us_Bool_Exp>;
};


export type Subscription_RootContact_Us_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Us_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Us_Order_By>>;
  where?: InputMaybe<Contact_Us_Bool_Exp>;
};


export type Subscription_RootContact_Us_By_PkArgs = {
  contact_us_id: Scalars['uuid'];
};


export type Subscription_RootContact_Us_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contact_Us_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Us_Bool_Exp>;
};


export type Subscription_RootEntryArgs = {
  distinct_on?: InputMaybe<Array<Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entry_Order_By>>;
  where?: InputMaybe<Entry_Bool_Exp>;
};


export type Subscription_RootEntry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entry_Order_By>>;
  where?: InputMaybe<Entry_Bool_Exp>;
};


export type Subscription_RootEntry_By_PkArgs = {
  entry_id: Scalars['uuid'];
};


export type Subscription_RootEntry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Entry_Stream_Cursor_Input>>;
  where?: InputMaybe<Entry_Bool_Exp>;
};


export type Subscription_RootInfoArgs = {
  distinct_on?: InputMaybe<Array<Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Info_Order_By>>;
  where?: InputMaybe<Info_Bool_Exp>;
};


export type Subscription_RootInfo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Info_Order_By>>;
  where?: InputMaybe<Info_Bool_Exp>;
};


export type Subscription_RootInfo_By_PkArgs = {
  info_id: Scalars['uuid'];
};


export type Subscription_RootInfo_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Info_Stream_Cursor_Input>>;
  where?: InputMaybe<Info_Bool_Exp>;
};


export type Subscription_RootKmlArgs = {
  distinct_on?: InputMaybe<Array<Kml_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kml_Order_By>>;
  where?: InputMaybe<Kml_Bool_Exp>;
};


export type Subscription_RootKml_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kml_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kml_Order_By>>;
  where?: InputMaybe<Kml_Bool_Exp>;
};


export type Subscription_RootKml_By_PkArgs = {
  kml_id: Scalars['uuid'];
};


export type Subscription_RootKml_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Kml_Stream_Cursor_Input>>;
  where?: InputMaybe<Kml_Bool_Exp>;
};


export type Subscription_RootMarkerArgs = {
  distinct_on?: InputMaybe<Array<Marker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Marker_Order_By>>;
  where?: InputMaybe<Marker_Bool_Exp>;
};


export type Subscription_RootMarker_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Marker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Marker_Order_By>>;
  where?: InputMaybe<Marker_Bool_Exp>;
};


export type Subscription_RootMarker_By_PkArgs = {
  marker_id: Scalars['uuid'];
};


export type Subscription_RootMarker_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Marker_Stream_Cursor_Input>>;
  where?: InputMaybe<Marker_Bool_Exp>;
};


export type Subscription_RootPageArgs = {
  distinct_on?: InputMaybe<Array<Page_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Page_Order_By>>;
  where?: InputMaybe<Page_Bool_Exp>;
};


export type Subscription_RootPage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Page_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Page_Order_By>>;
  where?: InputMaybe<Page_Bool_Exp>;
};


export type Subscription_RootPage_By_PkArgs = {
  page_id: Scalars['uuid'];
};


export type Subscription_RootPage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Page_Stream_Cursor_Input>>;
  where?: InputMaybe<Page_Bool_Exp>;
};


export type Subscription_RootTeamArgs = {
  distinct_on?: InputMaybe<Array<Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Order_By>>;
  where?: InputMaybe<Team_Bool_Exp>;
};


export type Subscription_RootTeam_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Order_By>>;
  where?: InputMaybe<Team_Bool_Exp>;
};


export type Subscription_RootTeam_By_PkArgs = {
  team_id: Scalars['uuid'];
};


export type Subscription_RootTeam_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Team_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_Bool_Exp>;
};

/** columns and relationships of "team" */
export type Team = {
  __typename?: 'team';
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  image_path: Scalars['String'];
  linkedin_url: Scalars['String'];
  name: Scalars['String'];
  team_id: Scalars['uuid'];
  twitter_url: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "team" */
export type Team_Aggregate = {
  __typename?: 'team_aggregate';
  aggregate?: Maybe<Team_Aggregate_Fields>;
  nodes: Array<Team>;
};

/** aggregate fields of "team" */
export type Team_Aggregate_Fields = {
  __typename?: 'team_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Team_Max_Fields>;
  min?: Maybe<Team_Min_Fields>;
};


/** aggregate fields of "team" */
export type Team_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "team". All fields are combined with a logical 'AND'. */
export type Team_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Bool_Exp>>;
  _not?: InputMaybe<Team_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  image_path?: InputMaybe<String_Comparison_Exp>;
  linkedin_url?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  twitter_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "team" */
export enum Team_Constraint {
  /** unique or primary key constraint on columns "team_id" */
  TeamPkey = 'team_pkey'
}

/** input type for inserting data into table "team" */
export type Team_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image_path?: InputMaybe<Scalars['String']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  twitter_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Team_Max_Fields = {
  __typename?: 'team_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  linkedin_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  twitter_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Team_Min_Fields = {
  __typename?: 'team_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  linkedin_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  twitter_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "team" */
export type Team_Mutation_Response = {
  __typename?: 'team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team>;
};

/** on_conflict condition type for table "team" */
export type Team_On_Conflict = {
  constraint: Team_Constraint;
  update_columns?: Array<Team_Update_Column>;
  where?: InputMaybe<Team_Bool_Exp>;
};

/** Ordering options when selecting data from "team". */
export type Team_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_path?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  twitter_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team */
export type Team_Pk_Columns_Input = {
  team_id: Scalars['uuid'];
};

/** select columns of table "team" */
export enum Team_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  Name = 'name',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  TwitterUrl = 'twitter_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "team" */
export type Team_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image_path?: InputMaybe<Scalars['String']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  twitter_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "team" */
export type Team_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image_path?: InputMaybe<Scalars['String']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  twitter_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "team" */
export enum Team_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  Name = 'name',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  TwitterUrl = 'twitter_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Team_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Team_Set_Input>;
  /** filter the rows which have to be updated */
  where: Team_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type TeamDataFragment = { __typename?: 'team', id?: string | null, name: string, description: string, email: string, image_path: string, twitter_url: string };

export type GetTeamQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamQuery = { __typename?: 'query_root', team: Array<{ __typename?: 'team', id?: string | null, name: string, description: string, email: string, image_path: string, twitter_url: string }> };

export type GetMarkersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMarkersQuery = { __typename?: 'query_root', marker: Array<{ __typename?: 'marker', lat: any, lng: any, videos?: string | null, entrevistados?: string | null }> };

export type GetMarkersIdiosincraciasHidricasQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMarkersIdiosincraciasHidricasQuery = { __typename?: 'query_root', marker: Array<{ __typename?: 'marker', lat: any, lng: any, title: string }> };

export type GetInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInfoQuery = { __typename?: 'query_root', info: Array<{ __typename?: 'info', title: string, subtitle?: string | null, description: string }> };

export type InsertContactUsMutationVariables = Exact<{
  data: Contact_Us_Insert_Input;
}>;


export type InsertContactUsMutation = { __typename?: 'mutation_root', insert_contact_us_one?: { __typename?: 'contact_us', contact_us_id: any, comment: string, subject: string, email: string } | null };

export const TeamDataFragmentDoc = gql`
    fragment TeamData on team {
  id
  name
  description
  email
  image_path
  twitter_url
}
    `;
export const GetTeamDocument = gql`
    query getTeam {
  team {
    ...TeamData
  }
}
    ${TeamDataFragmentDoc}`;

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
      }
export function useGetTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
        }
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>;
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>;
export type GetTeamQueryResult = Apollo.QueryResult<GetTeamQuery, GetTeamQueryVariables>;
export const GetMarkersDocument = gql`
    query getMarkers {
  marker(where: {page: {_eq: "voces_por_el_agua"}}) {
    lat
    lng
    videos
    entrevistados
  }
}
    `;

/**
 * __useGetMarkersQuery__
 *
 * To run a query within a React component, call `useGetMarkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarkersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarkersQuery(baseOptions?: Apollo.QueryHookOptions<GetMarkersQuery, GetMarkersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarkersQuery, GetMarkersQueryVariables>(GetMarkersDocument, options);
      }
export function useGetMarkersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarkersQuery, GetMarkersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarkersQuery, GetMarkersQueryVariables>(GetMarkersDocument, options);
        }
export type GetMarkersQueryHookResult = ReturnType<typeof useGetMarkersQuery>;
export type GetMarkersLazyQueryHookResult = ReturnType<typeof useGetMarkersLazyQuery>;
export type GetMarkersQueryResult = Apollo.QueryResult<GetMarkersQuery, GetMarkersQueryVariables>;
export const GetMarkersIdiosincraciasHidricasDocument = gql`
    query getMarkersIdiosincraciasHidricas {
  marker(where: {page: {_eq: "idiosincracias_hidricas"}}) {
    lat
    lng
    title
  }
}
    `;

/**
 * __useGetMarkersIdiosincraciasHidricasQuery__
 *
 * To run a query within a React component, call `useGetMarkersIdiosincraciasHidricasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarkersIdiosincraciasHidricasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarkersIdiosincraciasHidricasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarkersIdiosincraciasHidricasQuery(baseOptions?: Apollo.QueryHookOptions<GetMarkersIdiosincraciasHidricasQuery, GetMarkersIdiosincraciasHidricasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarkersIdiosincraciasHidricasQuery, GetMarkersIdiosincraciasHidricasQueryVariables>(GetMarkersIdiosincraciasHidricasDocument, options);
      }
export function useGetMarkersIdiosincraciasHidricasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarkersIdiosincraciasHidricasQuery, GetMarkersIdiosincraciasHidricasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarkersIdiosincraciasHidricasQuery, GetMarkersIdiosincraciasHidricasQueryVariables>(GetMarkersIdiosincraciasHidricasDocument, options);
        }
export type GetMarkersIdiosincraciasHidricasQueryHookResult = ReturnType<typeof useGetMarkersIdiosincraciasHidricasQuery>;
export type GetMarkersIdiosincraciasHidricasLazyQueryHookResult = ReturnType<typeof useGetMarkersIdiosincraciasHidricasLazyQuery>;
export type GetMarkersIdiosincraciasHidricasQueryResult = Apollo.QueryResult<GetMarkersIdiosincraciasHidricasQuery, GetMarkersIdiosincraciasHidricasQueryVariables>;
export const GetInfoDocument = gql`
    query getInfo {
  info {
    title
    subtitle
    description
  }
}
    `;

/**
 * __useGetInfoQuery__
 *
 * To run a query within a React component, call `useGetInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetInfoQuery, GetInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInfoQuery, GetInfoQueryVariables>(GetInfoDocument, options);
      }
export function useGetInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfoQuery, GetInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInfoQuery, GetInfoQueryVariables>(GetInfoDocument, options);
        }
export type GetInfoQueryHookResult = ReturnType<typeof useGetInfoQuery>;
export type GetInfoLazyQueryHookResult = ReturnType<typeof useGetInfoLazyQuery>;
export type GetInfoQueryResult = Apollo.QueryResult<GetInfoQuery, GetInfoQueryVariables>;
export const InsertContactUsDocument = gql`
    mutation insertContactUs($data: contact_us_insert_input!) {
  insert_contact_us_one(object: $data) {
    contact_us_id
    comment
    subject
    email
  }
}
    `;
export type InsertContactUsMutationFn = Apollo.MutationFunction<InsertContactUsMutation, InsertContactUsMutationVariables>;

/**
 * __useInsertContactUsMutation__
 *
 * To run a mutation, you first call `useInsertContactUsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContactUsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContactUsMutation, { data, loading, error }] = useInsertContactUsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertContactUsMutation(baseOptions?: Apollo.MutationHookOptions<InsertContactUsMutation, InsertContactUsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertContactUsMutation, InsertContactUsMutationVariables>(InsertContactUsDocument, options);
      }
export type InsertContactUsMutationHookResult = ReturnType<typeof useInsertContactUsMutation>;
export type InsertContactUsMutationResult = Apollo.MutationResult<InsertContactUsMutation>;
export type InsertContactUsMutationOptions = Apollo.BaseMutationOptions<InsertContactUsMutation, InsertContactUsMutationVariables>;