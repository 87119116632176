import { defaultDataIdFromObject, InMemoryCache, StoreObject } from '@apollo/client'

export const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {}
		}
	},
	dataIdFromObject(responseObject: StoreObject) {
		const table = responseObject.__typename as string
		const defaultPk = defaultDataIdFromObject(responseObject)
		if (!table || table.includes('aggregate')) return defaultPk

		let finalPkName = ''
		let finalPkVal = ''

		finalPkName = `${table}_id`
		if (!(finalPkName in responseObject)) {
			if (!finalPkName.includes('s_id')) return defaultPk

			finalPkName = finalPkName.replace('s_id', '_id')
			if (!(finalPkName in responseObject)) return defaultPk
		}
		finalPkVal = responseObject[finalPkName] as string
		finalPkVal = `${table}:${finalPkVal}`

		return finalPkVal
	}
})
